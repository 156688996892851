import { all, fork } from 'redux-saga/effects';

import app from './app.saga';
import films from './film.saga';
import banners from './banner.saga';
import options from './option.saga';
import search from './search.saga';

export default function* root() {
  yield all([
    fork(app),
    fork(films),
    fork(banners),
    fork(options),
    fork(search),
  ]);
}
