import React from 'react';

function About() {
  return (
    <div className="p-5 text-gray-400" style={{minHeight: 'calc(100vh - 150px)'}}>
      <h1 className="text-yellow-600 font-bold text-2xl">About Us</h1>

      <br/>

      <p>
        The Mindanao Film Archive is a program of the Mindanao Film & Television Development Foundation, Inc. Its
        mission is to collect, preserve, and provide access to the moving images that capture the rich and
        diverse culture and heritage of Mindanao.
        {' '}
      </p>

      <h4 className="mt-10 font-bold text-blue-400">Web App</h4>
      <p>
        Our web app makes the entire database of films in the Mindanao Film Archive accessible to anyone around
        the world. This database contains information about the moving images that may be useful for educators,
        students, researchers, historians, filmmakers, tourists, and anyone interested in Mindanao&apos;s moving
        images. Many moving images may also be viewed within the web app.
        {' '}
      </p>

      <h4 className="mt-10 font-bold text-blue-400">Interactive Kiosks</h4>
      <p>
        Our interactive kiosks provide access to most of the moving images in our archive. We partner with
        government agencies, organizations, companies, or institutions to setup interactive kiosks at strategic
        locations such as arts centers, libraries, museums, and educational institutions.
      </p>

      <h4 className="mt-10 font-bold text-blue-400">Film Screenings</h4>
      <p>
        We also provide special screenings of films from the archive in partnership with other groups or
        organizations. These screenings can be specifically programmed according to certain themes or topics for
        educational purposes or for cultural appreciation.
      </p>

      <h4 className="mt-10 font-bold text-blue-400">Film Collection</h4>
      <p>
        We are constantly in search for new films or footages to include in the collection of the Mindanao Film
        Archive. Rights holders of moving images may contact us to have their materials considered for priority
        archiving.
      </p>
    </div>
  );
}

export default About;
