import React, {useEffect} from 'react';
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'
import {isMobile} from "react-device-detect";

function FilmMedia({photos, music, video}) {

  return (
    <div
      className="bg-black rounded-b-lg bg-opacity-10 p-5 pb-10 details text-gray-400 grid grid-cols-1 overlay-hidden gap-10 xl:text-2xl grid-flow-row"
    >
      {photos?.length ? (
        <div className="mb-3">
          <div className="text-2xl text-yellow-300">Photos</div>
          <div className="w-100 gap-3 grid xl:grid-cols-2 overflow-auto">
            {photos?.map((item, index) => <img key={index} src={item.tag_name} width={250} alt=""/>)}
          </div>
        </div>
      ) : ''}

      {video?.length ? (
        <div className="mb-3">
          <div className="text-2xl text-yellow-300">Videos</div>
          <div className="w-100 gap-3 grid xl:grid-cols-2">
            {video?.map((item, index) => (
                <div className="" key={index}>
                  <ReactPlayer width="auto" pip controls url={item.tag_name}/>
                </div>
              )
            )}
          </div>
        </div>
      ) : ''}

      {music?.length ? (
        <div className="mb-3">
          <div className="text-2xl text-yellow-300">Audio</div>
          <div className="w-100 gap-3 grid xlgrid-cols-2 overflow-auto">
            {music?.map((item, index) => <ReactPlayer key={index} url={item.tag_name}/>)}
          </div>
        </div>
      ) : ''}
    </div>
  );
}

FilmMedia.propTypes = {
  photos: PropTypes.array,
  music: PropTypes.array,
  video: PropTypes.array
}

FilmMedia.defaultProps = {
  photos: [],
  music: [],
  video: []
}

export default FilmMedia;
