import React, {createRef, Component, useRef} from 'react'
import ReactPlayer from "react-player";
import PropTypes from 'prop-types'
import {API_BASE_URL, APP_PLATFORM} from "../../shared/constants/app.constant";
import KioskOnly from "./kiosk-only";
import NoPermission from "./no-permission";
import CustomControls from "./custom-controls";
import FilmFullscreen from "../modals/film-fullscreen";
import SensitiveFlags from "../modals/sensitive-flags";

class Video extends Component {
  constructor(props) {
    super(props);
    this.thumbnail = `${API_BASE_URL}/uploads/${this.props.film?.thumbnail}`

    this.state = {
      url: null,
      pip: false,
      playing: false,
      controls: false,
      volume: 1,
      fullScreenVolume: 1,
      fullScreenPlaying: false,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      seek: 0,
      playbackRate: 1.0,
      loop: false,
      imageDimension: {height: 360},
      light: this.thumbnail,
      isForWeb: null,
      isForKiosk: null,
      toShowAlert: true,
      isAcceptedSensitivity: false,
      fullScreenPlayer: {
        url: null,
        pip: false,
        playing: false,
        controls: false,
        volume: 1,
        fullScreenVolume: 1,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        seek: 0,
        playbackRate: 1.0,
        loop: false,
        light: this.thumbnail,
      }
    }

    this.resizeOverlay = this.resizeOverlay.bind(this)
    this.handleSeekMouseDown = this.handleSeekMouseDown.bind(this)
    this.handleSeekChange = this.handleSeekChange.bind(this)
    this.handleSeekMouseUp = this.handleSeekMouseUp.bind(this)
    this.handleDuration = this.handleDuration.bind(this)
    this.handleProgress = this.handleProgress.bind(this)
    this.showFullscreen = this.showFullscreen.bind(this)
    this.validateSensitiveFlags = this.validateSensitiveFlags.bind(this)
    this.handleCancelSensitiveModal = this.handleCancelSensitiveModal.bind(this)
    this.handleAcceptSensitiveModal = this.handleAcceptSensitiveModal.bind(this)
    this.RenderFullScreen = this.RenderFullScreen.bind(this)
    this.filmThumbnailRef = createRef()
  }

  componentDidMount() {
    this.thumbnail = `${API_BASE_URL}/uploads/${this.props.film?.thumbnail}`

    this.setState({
      isForWeb: this.props.film?.permissions?.includes('website'),
      isForKiosk: this.props.film?.permissions?.includes('kiosk')
    })
    addEventListener('resize', this.resizeOverlay);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.thumbnail !== `${API_BASE_URL}/uploads/${this.props.film?.thumbnail}`) {
      this.thumbnail = `${API_BASE_URL}/uploads/${this.props.film?.thumbnail}`
      this.setState({
        light: this.thumbnail
      })
    }

    if (prevProps.film !== this.props.film) {

      this.setState({
        isForWeb: this.props.film?.permissions?.includes('website'),
        isForKiosk: this.props.film?.permissions?.includes('kiosk')
      })
    }

    if (document.querySelector('.video-thumbnail')?.clientWidth !== this.state.imageDimension.width && this.state.imageDimension.width !== this.filmThumbnailRef?.current.width) {
      setTimeout(() => {
        this.resizeOverlay()
      }, 500)
    }

    if (prevState.isForKiosk !== this.state.isForKiosk ||
      prevState.isForWeb !== this.state.isForWeb) {
      this.resizeOverlay()
    }
  }

  resizeOverlay() {
    this.setState({
      imageDimension: {
        width: document.querySelector('.video-thumbnail')?.clientWidth,
        height: document.querySelector('.video-thumbnail')?.clientHeight || 280
      }
    })
  }

  handleSeekMouseDown = e => {
    this.setState({seeking: true})
  }

  handleSeekChange = e => {
    this.player?.seekTo(parseFloat(e.target.value))
    this.setState({played: parseFloat(e.target.value)})
  }

  handleSeekMouseUp = e => {
    this.setState({seeking: false})
  }

  handleCancelSensitiveModal() {
    if (this.state.showFullscreen) {
      this.setState({
        toShowAlert: true,
        isAcceptedSensitivity: false,
        showSensitiveFlags: false,
        fullScreenPlaying: false,
        fullScreenPlayer: {
          ...this.state.fullScreenPlayer,
          light: false
        }
      })
    } else {
      this.setState({
        toShowAlert: true,
        isAcceptedSensitivity: false,
        showSensitiveFlags: false,
        playing: false,
        light: this.thumbnail
      })
    }
  }

  handleAcceptSensitiveModal() {
    if (this.state.showFullscreen) {
      this.setState({
        toShowAlert: false,
        isAcceptedSensitivity: true,
        showSensitiveFlags: false,
      })
    } else {
      this.setState({
        toShowAlert: false,
        isAcceptedSensitivity: true,
        showSensitiveFlags: false,
      })
    }

    this.handlePlayPause()
  }

  validateSensitiveFlags() {
    const showSensitiveFlags = (this.props.film?.sensitive_flags?.length && !this.state.isAcceptedSensitivity) && this.state.toShowAlert
    if (this.state.showFullscreen) {
      this.setState({
        fullScreenPlaying: !showSensitiveFlags,
        fullScreenPlayer: {
          ...this.state.fullScreenPlayer,
          light: !showSensitiveFlags ? false : this.thumbnail
        },
        showSensitiveFlags
      })
    } else {
      this.setState({
        playing: !showSensitiveFlags,
        light: !showSensitiveFlags ? false : this.thumbnail,
        showSensitiveFlags
      })
    }
  }

  handleProgress = progress => {
    this.setState({progress})
  }

  handlePlayPause = () => {
    this.setState({
      light: false,
      playing: !this.state.playing
    })
  }

  handlePlayPauseFullscreen = (payload) => {
    this.setState({
      fullScreenPlaying: !this.state.fullScreenPlaying,
      fullScreenPlayer: {
        ...this.state.fullScreenPlayer,
        light: false
      },
    })
  }

  showFullscreen = () => {
    this.setState({
      showFullscreen: !this.state.showFullscreen,
      light: this.thumbnail
    })
  }

  handleDuration(duration) {
    this.setState({
      duration
    })
  }

  ReactPlayerRef = player => this.player = player

  embedOptions = {
    modestbranding: 1,
    iv_load_policy: 3
  }
  RenderFullScreen = () => (
    <FilmFullscreen
      isShowFullscreen={this.state.showFullscreen}
      title=''
      onClose={
        this.showFullscreen
      }
      content={
        <>
          <div className="relative top-0" style={{zIndex: 1000}} onClick={this.handlePlayPauseFullscreen}>
            <img
              src={this.thumbnail}
              alt={this.props.film.title}
              style={{height: '100vh'}}
              className="w-full z-0 absolute opacity-0 video-thumbnail rounded shadow-lg"
              ref={this.filmThumbnailRef}
            />
          </div>
          <ReactPlayer
            ref={this.ReactPlayerRef}
            pip={false}
            playing={this.state.fullScreenPlaying}
            width="100%"
            height="100vh"
            light={this.state.fullScreenPlayer.light}
            volume={1}
            onStart={this.validateSensitiveFlags}
            url={this.props.film?.film_filename}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
          />

        </>
      }
      controls={
        <CustomControls
          isFullscreen
          style={{zIndex: 2000}}
          playing={this.state.fullScreenPlaying}
          progress={this.state.progress}
          duration={this.state.duration}
          handlePlayPause={this.handlePlayPause}
          handlePlayPauseFullscreen={this.handlePlayPauseFullscreen}
          showFullscreen={this.showFullscreen}
          onMouseDown={this.handleSeekMouseDown}
          onChange={this.handleSeekChange}
          onMouseUp={this.handleSeekMouseUp}
        />
      }
    />
  )


  render() {
    return (
      <>
        <SensitiveFlags
          handleClose={this.handleCancelSensitiveModal}
          handleAccept={this.handleAcceptSensitiveModal}
          showModal={this.state.showSensitiveFlags}
          flags={this.props.film.sensitive_flags}
        />
        {APP_PLATFORM === 'web' ? (
          <div className="">
            {this.state.isForWeb ? (
              <div>
                {this.RenderFullScreen()}
                <div className="relative top-0" style={{zIndex: 1000}} onClick={this.handlePlayPause}>
                  <img
                    src={this.thumbnail}
                    alt={this.props.film.title}
                    className="w-full z-0 absolute opacity-0 video-thumbnail rounded shadow-lg"
                    ref={this.filmThumbnailRef}
                  />
                </div>
                <ReactPlayer
                  ref={this.ReactPlayerRef}
                  pip={false}
                  playing={this.state.playing}
                  width={this.state.imageDimension?.width}
                  height={this.state.imageDimension?.height}
                  light={this.state.light}
                  volume={this.state.volume}
                  url={this.props.film?.film_filename}
                  onStart={this.validateSensitiveFlags}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                />
                <CustomControls
                  style={{zIndex: 2000}}
                  playing={this.state.playing}
                  progress={this.state.progress}
                  duration={this.state.duration}
                  handlePlayPause={this.handlePlayPause}
                  showFullscreen={this.showFullscreen}
                  onMouseDown={this.handleSeekMouseDown}
                  onChange={this.handleSeekChange}
                  onMouseUp={this.handleSeekMouseUp}
                />
              </div>
            ) : (
              <div>
                <div className="absolute" style={{
                  color: '#cccccc',
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'end',
                  width: this.state.imageDimension?.width,
                  height: this.state.imageDimension?.height,
                }}
                >
                  {Object.keys(this.state.imageDimension)?.length ? this.state.isForKiosk ?
                    <KioskOnly isForKiosk={this.state.isForKiosk}/> : <NoPermission/> : ''}
                </div>
                <img
                  src={this.thumbnail}
                  alt={this.props.film.title}
                  className="w-full video-thumbnail rounded shadow-lg"
                  ref={this.filmThumbnailRef}
                />

              </div>
            )
            }
          </div>
        ) : (
          <div className="">
            {
              !this.state.isForKiosk ? (
                <div>
                  <div className="absolute" style={{
                    color: '#cccccc',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'end',
                    width: this.state.imageDimension?.width,
                    height: this.state.imageDimension?.height || 300,
                  }}
                  >
                    {Object.keys(this.state.imageDimension)?.length ? <NoPermission/> : ''}
                  </div>
                  <img
                    src={this.thumbnail}
                    alt={this.props.film.title}
                    className="w-full video-thumbnail rounded shadow-lg"
                    ref={this.filmThumbnailRef}
                  />
                </div>
              ) : (
                <div>
                  {this.RenderFullScreen()}
                  <div className="relative top-0" style={{zIndex: 1000}} onClick={this.handlePlayPause}>
                    <img
                      src={this.thumbnail}
                      alt={this.props.film.title}
                      className="w-full z-0 absolute opacity-0 video-thumbnail rounded shadow-lg"
                      ref={this.filmThumbnailRef}
                    />
                  </div>
                  <ReactPlayer
                    ref={this.ReactPlayerRef}
                    pip={false}
                    playing={this.state.playing}
                    width={this.state.imageDimension?.width}
                    height={this.state.imageDimension?.height || 300}
                    light={this.state.light}
                    volume={this.state.volume}
                    url={this.props.film?.film_filename}
                    onStart={this.validateSensitiveFlags}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration}
                  />
                  <CustomControls
                    style={{zIndex: 2000}}
                    playing={this.state.playing}
                    progress={this.state.progress}
                    duration={this.state.duration}
                    handlePlayPause={this.handlePlayPause}
                    onMouseDown={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange}
                    onMouseUp={this.handleSeekMouseUp}
                    showFullscreen={this.showFullscreen}
                  />
                </div>
              )
            }
          </div>
        )}
      </>
    )
  }

}

Video.propTypes = {
  film: PropTypes.object.isRequired,
  sendTheAlert: PropTypes.any
}

export default Video