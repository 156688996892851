import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import FilmCard from "../components/film-card";
import Spinner from "../components/spinner/spinner";
import {useParams} from "react-router";
import searchSlice from "../shared/reducers/search.slice";

const Search = () => {
  const dispatch = useDispatch()
  const match = useParams()
  const {searchFilms} = searchSlice.actions
  const {searchMetaOptions} = useSelector((state) => state.options);
  const {searchResult, status} = useSelector(state => state.search)
  const [films, setFilms] = useState([])

  useEffect(() => {
    const {search, field} = match
    dispatch(searchFilms({search, field}))
  }, [match])

  useEffect(() => {
    if (status.submitted && !status.loading) {
      if (status.success) {
        setFilms(searchResult)
      }
    }
  }, [status])

  return !status.loading ? (
    <div>
      <h1 className="text-gray-400 text-2xl mt-10 mx-2">
        Searching
        for &ldquo;{match.search}&rdquo; in &ldquo;{searchMetaOptions?.find(meta => meta.value === match.field)?.name}&rdquo;</h1>
      {films?.length ? (
        <div className="mt-10 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5"  style={{minHeight: 'calc(100vh - 220px)'}}>
          {
            films?.map(
              film => <FilmCard key={film.id} film={film}/>
            )
          }</div>
      ) : (
        <div className="text-gray-400 text-center text-uppercase flex justify-center items-center"
             style={{minHeight: 'calc(100vh - 220px)'}}>
          No films found with that criteria.
        </div>
      )
      }

    </div>
  ) : (
    <Spinner/>
  )
}

export default Search
