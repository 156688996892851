import './stylesheets/main.scss';
import './stylesheets/tailwind.css';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import reportWebVitals from './reportWebVitals';
import configureAppStore from './shared/configs/store.config';
import {Spinner} from "./components/spinner";
import AppRoutes from "./pages/app-routes";

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureAppStore()
const persistor = persistStore(store)

root.render(
    <Provider store={store}>
      <PersistGate loading={<Spinner/>} persistor={persistor}>
        <Suspense fallback={<Spinner/>}>
          <BrowserRouter>
              <AppRoutes/>
          </BrowserRouter>
        </Suspense>
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
