import React from 'react';
import {ArrowLeftOutlined, ReadOutlined, SearchOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {useMatch, useNavigate} from 'react-router';
import searchSlice from '../shared/reducers/search.slice';
import logoImage from '../static/images/logo.png'

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {toggleSearchBar} = searchSlice.actions;
  const goBack = () => navigate(-1);
  const changePageTo = (uri) => navigate(`/${uri}`);
  const {showSearchBar} = useSelector(state => state.search)

  const showSearch = () => {
    if (!showSearchBar)
      dispatch(toggleSearchBar(true));
  };

  const isAbout = useMatch('about');
  const isLocations = useMatch('kiosk-location');
  const isTerms = useMatch('terms-and-conditions');
  const isCredits = useMatch('partners');
  const isContact = useMatch('contact');

  return (
    <div className="flex-row px-2 md:px-20 py-2 md:flex content-center">
      <div className="flex flex-1">
        <div title="Back" className="self-center cursor-pointer text-gray-400 text-2xl mr-3" onClick={goBack}>
          <ArrowLeftOutlined/>
        </div>
        <div title="home" className="w-full md:w-2 md:min-w-max cursor-pointer" onClick={() => changePageTo('')}>
          <img src={logoImage} width={250} alt=""/>
        </div>
        <div className="self-center flex text-gray-400 text-2xl md:ml-10 ">
          <div>
            <ReadOutlined title="Browse" className="cursor-pointer focus:outline-none"
                          onClick={() => changePageTo('browse')}/>
          </div>
          <div>
            <div
              title="Search"
              className="absolute cursor-pointer"
              style={{width: 50, height: 40}}
              id="search-icon"
              onClick={() => {
                showSearch();
              }}/>
            <SearchOutlined
              className="ml-5 cursor-pointer focus:outline-none"
            />
          </div>
        </div>
      </div>
      <div className="flex pt-30 text-gray-400 space-x-5 self-center md:right justify-center mt-2 md:mt-0">
        <div
          className={`xl:text-xl 2xl:text-2xl sm:text-xs text-xs text-center cursor-pointer ${isAbout ? 'font-bold' : ''}`}
          onClick={() => changePageTo('about')}
        >
          About
        </div>
        <div
          className={`xl:text-xl 2xl:text-2xl sm:text-xs text-xs text-center cursor-pointer ${isLocations ? 'font-bold' : ''}`}
          onClick={() => changePageTo('kiosk-location')}
        >
          Kiosk Locations
        </div>
        <div
          className={`xl:text-xl 2xl:text-2xl sm:text-xs text-xs text-center cursor-pointer ${isTerms ? 'font-bold' : ''}`}
          onClick={() => changePageTo('terms-and-conditions')}
        >
          Terms of Use
        </div>
        <div
          className={`xl:text-xl 2xl:text-2xl sm:text-xs text-xs text-center cursor-pointer ${isCredits ? 'font-bold' : ''}`}
          onClick={() => changePageTo('partners')}
        >
          Credits
        </div>
        <div
          className={`xl:text-xl 2xl:text-2xl sm:text-xs text-xs text-center cursor-pointer ${isContact ? 'font-bold' : ''}`}
          onClick={() => changePageTo('contact')}
        >
          Contact
        </div>
      </div>
    </div>
  );
}

export default Header;
