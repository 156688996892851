import React, {useEffect, useState} from 'react'
import ModalsHoc from "./modals-hoc";
import PropTypes from 'prop-types'

const SensitiveFlags = ({flags, handleAccept: handleAcceptModal, handleClose: handleCloseModal, showModal}) => {
  const [show, setShow] = useState(showModal);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // useEffect(() => {
  //   setShow(showModal)
  //   console.log({showModal})
  // }, [showModal])

  const handleContinue = () => {
    // handleClose()
    handleAcceptModal()
  }

  const handleCancel = () => {
    // handleClose()
    handleCloseModal()
  }

  return (
    <ModalsHoc
      heading="Sensitive Flags"
      show={showModal}
      handleShow={handleShow}
      handleClose={handleClose}>
      <div className="p-4">
        <p className="text-zinc-300 pb-2">
          Warning, this moving image contains the following content that may not be suitable for some audiences. Viewer
          discretion is advised:
        </p>
        <ul className="text-zinc-300 list-disc px-10">
          {flags?.map(flag => (
            <li key={flag} className="py-3 text-red-400">{flag}</li>
          ))}
        </ul>
        <div className="flex justify-content-end p-6 space-x-2 rounded-b border-t border-zinc-500 dark:border-gray-600">
          <button
            onClick={handleCancel}
            type="button"
            className="text-zinc-200 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Cancel
          </button>
          <button
            onClick={handleContinue}
            type="button"
            className="text-zinc-200 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Continue
          </button>
        </div>
      </div>

    </ModalsHoc>
  )
}

SensitiveFlags.defaultProps = {
  flags: [],
  showModal: false
}
SensitiveFlags.propTypes = {
  showModal: PropTypes.bool,
  flags: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
}
export default SensitiveFlags