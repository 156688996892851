import React, {useEffect, useState} from 'react'
import FilmDetails from "../components/film-details";
import Tabs from "../components/tabs";
import FilmCredits from "../components/film-credits";
import FilmInfo from "../components/film-info";
import FilmMedia from "../components/film-media";
import {filter, map} from "lodash";
import Related from "../components/related/related";
import {useDispatch, useSelector} from "react-redux";
import filmsSlice from "../shared/reducers/film.slice";
import {useParams} from "react-router";
import Spinner from "../components/spinner/spinner";
import {FilmVideo} from "../components/film-video";

const Film = () => {
  const dispatch = useDispatch()
  const match = useParams()
  const {getFilm, getRelatedFilms} = filmsSlice.actions
  const {film: filmData, relatedFilms, status} = useSelector(state => state.films)
  const [activeTab, setActiveTab] = useState('details')
  const [film, setFilm] = useState()

  useEffect(() => {
    dispatch(getFilm({id: match.id}))
    dispatch(getRelatedFilms({id: match.id}))
    setActiveTab('details')
  }, [match])

  useEffect(() => {
    if (status.submitted && !status.loading) {
      if (status.type === 'getFilm' && !status.success) {
        location.reload()
      }
    }
  }, [status]);

  useEffect(() => {
    if (filmData?.length)
      processMetadata(filmData[0].metadata)
  }, [filmData])

  const processMetadata = metadata => {
    if (!metadata) return

    let film = {}

    film.genre = map(
      filter(metadata, film => film.sections_name === "genre"),
      "tag_name"
    );

    film.themes = map(
      filter(metadata, film => film.sections_name === "themes"),
      "tag_name"
    );

    film.languages = map(
      filter(metadata, film => film.sections_name === "languages"),
      "tag_name"
    );
    film.format_filmed = map(
      filter(metadata, film => film.sections_name === "format_filmed"),
      "tag_name"
    );
    film.format_archived = map(
      filter(metadata, film => film.sections_name === "format_archived"),
      "tag_name"
    );
    film.subtitles = map(
      filter(metadata, film => film.sections_name === "subtitles"),
      "tag_name"
    );

    film.locations = metadata.filter(film => {
      if (film.sections_name === "locations") return film.tag_name;
    });

    film.courtesy = metadata.filter(film => {
      if (film.sections_name === "courtesy") return film.tag_name;
    });

    film.rights_holders = metadata.filter(film => {
      if (film.sections_name === "rights_holders") return film.tag_name;
    });

    film.place_origin = metadata.filter(film => {
      if (film.sections_name === "place_origin") return film.tag_name;
    });


    film.awards = metadata.filter(film => {
      if (film.sections_name === "awards") return film.tag_name;
    });
    if (!film.awards || !film.awards.length)
      film.awards = [];

    film.permissions = map(
      filter(metadata, film => film.sections_name === "permissions"),
      "tag_name"
    );

    film.sensitive_flags = map(
      filter(metadata, film => film.sections_name === "sensitive_flags"),
      "tag_name"
    );

    film.links = metadata.filter(film => {
      if (film.sections_name === "links") return film.tag_name;
    });
    if (!film.links || !film.links.length)
      film.links = [];

    film.credit_crews = map(
      filter(metadata, film => film.sections_name === "credit_crews"),
      item => {
        return {
          name: item.tag_name,
          order: parseInt(item.order, 10),
          position: item.crew_position
        };
      }
    );

    film.credit_crews = filter(film.credit_crews, item => item.name || item.position)

    film.credit_casts = map(
      filter(metadata, film => film.sections_name === "credit_casts"),
      item => {
        return {
          name: item.tag_name,
          order: parseInt(item.order, 10),
          position: item.crew_position
        };
      }
    );
    film.credit_casts = filter(film.credit_casts, item => item.name || item.position)

    film.credit_acknowledgement = map(
      filter(metadata, film => film.sections_name === "credit_acknowledgement"),
      item => {
        return {
          name: item.tag_name,
          order: parseInt(item.order, 10),
          position: item.crew_position
        };
      }
    );
    film.credit_acknowledgement = filter(film.credit_acknowledgement, item => item.name || item.position)

    film.credit_others = map(
      filter(metadata, film => film.sections_name === "credit_others"),
      item => {
        return {
          name: item.tag_name,
          order: item.order,
          description: item.others
        };
      }
    );
    film.credit_others = filter(film.credit_others, item => item.name || item.position)

    film.media_videos = metadata.filter(film => {
      if (film.sections_name === "media_videos") return film.tag_name;
    });

    film.media_photos = metadata.filter(film => {
      if (film.sections_name === "media_photos") return film.tag_name;
    });

    film.media_music = metadata.filter(film => {
      if (film.sections_name === "media_music") return film.tag_name;
    });
    setFilm({...filmData[0], ...film})
  };

  const details = [
    {
      label: 'Year',
      value: film?.year
    },
    {
      label: 'Duration',
      value: film?.duration
    },
    {
      label: 'Type',
      value: film?.type?.includes('Short Film') ? 'Short Film' : 'Full-length Film'
    },
    {
      label: 'Genre',
      value: film?.genre
        .map(item => item.split('/').join(`
      `)).join(`, `)
    },
    {
      label: 'Themes',
      value: film?.themes.join(`, `)
    },
    {
      label: 'Place of Origin',
      value: film?.place_origin
        .map(function (item) {
          return item.tag_name;
        }).join(`
      `)
    },
    {
      label: 'Locations',
      value: film?.locations
        .map(function (item) {
          return item.tag_name;
        }).join(`
      `)
    },
    {
      label: 'Language',
      value: film?.languages
        .map(function (item) {
          return item;
        }).join(`, `)
    },
    {
      label: 'Subtitles',
      value: film?.subtitles
        .map(function (item) {
          return item;
        }).join(`, `)
    },
    {
      label: 'Aspect Ratio',
      value: film?.aspect_ratio
    },
    {
      label: 'Format Filmed In',
      value: film?.format_filmed.join(', ')
    },
    {
      label: 'Format Archived From',
      value: film?.format_archived
    },
    {
      label: 'Courtesy',
      value: film?.courtesy
        .map(function (item) {
          return item.tag_name;
        }).join(`, `)
    },
    {
      label: 'Rights Holder',
      value: film?.rights_holders
        .map(function (item) {
          return item.tag_name;
        }).join(`, `)
    },
  ]

  return film && !status.loading ? (
    <div className="p-5 xl:text-xl 2xl:text-3xl transition ease-in duration-700 whitespace-pre-line">
      <div className="my-5 xs:p-10">
        <div>
          <div className="video-placeholder bg-black bg-opacity-10 md:flex sm:flex-row p-1 items-start">
            <FilmVideo film={film}/>
            <div
              className="ml-2 flex-grow text-gray-400 p-5 divide-y-2 divide-gray-600 md:w-8/12 divide-dashed">
              <div className="text-uppercase font-bold mb-4 text-white text-3xl">{film.title}</div>
              <div className="pt-4">
                {film.description}
              </div>
            </div>
          </div>

          <Tabs {...{activeTab, setActiveTab}}
                visible={{
                  info: film.notes || film.links?.length || film.awards?.length,
                  media: film?.media_photos?.length || film?.media_music?.length || film?.media_videos?.length
                }}/>

          {activeTab === 'details' ? (<FilmDetails {...{details}} />) : ('')}
          {activeTab === 'credits' ? (
            <FilmCredits credit_casts={film.credit_casts} credit_crews={film.credit_crews}
                         credit_others={film.credit_others}
                         credit_acknowledgement={film.credit_acknowledgement}/>) : ('')}
          {activeTab === 'info' ? (<FilmInfo notes={film.notes} links={film?.links} awards={film?.awards}/>) : ('')}
          {activeTab === 'media' ? (
            <FilmMedia photos={film?.media_photos} music={film?.media_music} video={film?.media_videos}/>) : ('')}
          {relatedFilms?.data?.length ?
            <Related film={film} films={relatedFilms?.data}/>
            : ''}
        </div>
      </div>
    </div>
  ) : (
    <Spinner/>
  )
}

export default Film
