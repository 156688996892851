import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import searchService from '../services/search.service';
import searchSlice from '../reducers/search.slice';

const {
  searchFilms,
  searchFilmsSuccess,
  searchFilmsFailed,
  searchArchives,
  searchArchivesSuccess,
  searchArchivesFailed,
} = searchSlice.actions;

function* searchFilmsSaga(action) {
  try {
    const { data } = yield call(searchService.searchFilms, action.payload);
    yield put(searchFilmsSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(searchService.searchFilms, action.payload);
      yield put(searchFilmsSuccess(data));
    } catch (error) {
      yield put(searchFilmsFailed(error));
    }
  }
}
function* searchArchivesSaga(action) {
  try {
    const { data } = yield call(searchService.searchArchives, action.payload);
    yield put(searchArchivesSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(searchService.searchArchives, action.payload);
      yield put(searchArchivesSuccess(data));
    } catch (error) {
      yield put(searchArchivesFailed(error));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(searchFilms, searchFilmsSaga),
    takeLatest(searchArchives, searchArchivesSaga),
  ]);
}
