import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar'
import ModalsHoc from "./modals-hoc";
import appSlice from "../../shared/reducers/app.slice";
import {APP_PLATFORM} from "../../shared/constants/app.constant";
import KioskTerms from "./kiosk-terms";
import WebTerms from "./web-terms";

const Terms = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const {showTermsModal} = useSelector(state => state.app)
  const {acceptTerms} = appSlice.actions

  useEffect(() => {
    setShow(typeof showTermsModal === 'undefined' || showTermsModal)
  }, [])

  const handleAccept = () => {
    dispatch(acceptTerms())
    handleClose()
  }

  return (
    <ModalsHoc
      heading={APP_PLATFORM === 'web' ? 'Welcome to the Mindanao Film Archive!' : 'Welcome to the Mindanao Film Archive kiosk!'}
      show={show} handleShow={handleShow}
      handleClose={handleClose}>
        <div>
          {APP_PLATFORM === 'web' ? (
            <WebTerms/>
          ) : ''}

          {APP_PLATFORM === 'kiosk' ? (
            <KioskTerms/>
          ) : ''}

          <div className="flex items-center p-6 space-x-2 rounded-b border-t border-zinc-500 dark:border-gray-600">
            <button
              onClick={handleAccept}
              type="button"
              className="text-zinc-200 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Accept
            </button>
          </div>
        </div>

    </ModalsHoc>
  )
}

export default Terms