import React from 'react'
import PropTypes from 'prop-types'
import {orderBy} from 'lodash'

const FilmCredits = ({credit_casts, credit_crews, credit_others, credit_acknowledgement}) => {
  return (
    <div
      className="bg-black rounded-b-lg bg-opacity-10 p-5 gap-20 pb-10 details text-gray-400 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 xl:text-2xl grid-flow-row">
      {credit_casts?.length ? (
        <div>
          <div className="text-2xl text-yellow-300">Cast</div>
          {
            orderBy(credit_casts, ['order'], ['asc']).map((cast) =>
              <div key={cast.order} className="mt-7">
                <div className="text-blue-400">{cast.position}</div>
                <div>{cast.name}</div>
              </div>
            )
          }
        </div>
      ) : ''}

      {credit_crews?.length ? (
        <div>
          <div className="text-2xl text-yellow-300">Crew</div>
          {
            orderBy(credit_crews, ['order'], ['asc']).map((crew) =>
              <div key={crew.order} className="mt-7">
                <div className="text-blue-400">{crew.position}</div>
                <div>{crew.name}</div>
              </div>
            )
          }
        </div>
      ) : ''}

      {credit_acknowledgement?.length ? (
        <div>
          <div className="text-2xl text-yellow-300">Acknowledgment</div>
          {
            orderBy(credit_acknowledgement, ['order'], ['asc']).map((ack) =>
              <div key={ack.order} className="mt-7">
                <div className="text-blue-400">{ack.position}</div>
                <div>{ack.name}</div>
              </div>
            )
          }
        </div>
      ) : ''}

      {credit_others?.length ? (
        <div>
          <div className="text-2xl text-yellow-300">Others</div>
          {
            orderBy(credit_others, ['order'], ['asc']).map((other) =>
              <div key={other.order} className="mt-7">
                <div className="text-blue-400">{other.description}</div>
                <div>{other.name}</div>
              </div>
            )
          }
        </div>
      ) : ''}
    </div>
  )
}

FilmCredits.defaultProps = {
  credit_casts: [],
  credit_crews: [],
  credit_others: [],
  credit_acknowledgement: [],
}

FilmCredits.propTypes = {
  credit_casts: PropTypes.array,
  credit_crews: PropTypes.array,
  credit_acknowledgement: PropTypes.array,
  credit_others: PropTypes.array
}

export default FilmCredits