import { createSlice } from '@reduxjs/toolkit';
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus,
} from '../constants/status.constant';

const initialState = {
  showSearchBar: false,
  status: {
    submitted: false,
    success: false,
    message: '',
    loading: false,
    type: '',
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    toggleSearchBar: (state, {payload}) => ({
      ...state,
      showSearchBar: payload,
    }),
    searchFilms: (state, payload) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'searchFilms',
      },
    }),
    searchFilmsSuccess: (state, { payload }) => ({
      ...state,
      searchResult: payload,
      status: {
        ...successActionStatus,
        type: 'searchFilms',
      },
    }),
    searchFilmsFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'searchFilms',
      },
    }),
    searchArchives: (state, payload) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'searchArchives',
      },
    }),
    searchArchivesSuccess: (state, { payload }) => ({
      ...state,
      searchResult: payload,
      status: {
        ...successActionStatus,
        type: 'searchArchives',
      },
    }),
    searchArchivesFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'searchArchives',
      },
    }),
    reset: (state) => ({
      ...state,
      searchResult: [],
      status: {
        ...resetActionStatus,
      },
    }),
  },
});

export default searchSlice;
