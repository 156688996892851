import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {uniqBy} from 'lodash'
import optionSlice from "../shared/reducers/option.slice";
import searchSlice from "../shared/reducers/search.slice";
import FilmCard from "../components/film-card";
import {Spinner} from "../components/spinner";
import {Dropdown} from "react-bootstrap";

const Archives = () => {
  const dispatch = useDispatch()

  const {searchArchives, reset: resetSearch} = searchSlice.actions
  const {getFilmTypes, getFilmThemes, getFilmGenre, reset} = optionSlice.actions
  const [selectedType, setSelectedType] = useState()
  const [selectedTheme, setSelectedTheme] = useState()
  const [selectedGenre, setSelectedGenre] = useState()
  const [isSearchStarted, setIsSearchStarted] = useState()
  const {options} = useSelector(state => state.options)
  const {searchResult, status} = useSelector(state => state.search)

  const [themeOptions, setThemeOptions] = useState()
  const [typeOptions, setTypeOptions] = useState()
  const [genreOptions, setGenreOptions] = useState()

  const getFilmCategories = () => {
    dispatch(getFilmTypes())
    dispatch(getFilmThemes())
    dispatch(getFilmGenre())
  }

  useEffect(() => {
    getFilmCategories()
    return () => {
      dispatch(resetSearch())
    }
  }, []);

  const getExistingCategories = ({category}) => searchResult.map(film => film?.metadata?.filter(meta => meta.sections_name === category)
    .map(item => item.tag_name))
    .flat()
    .reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
    )
    .map(item => ({Value: item, Name: item}))

  const getExistingType = () => searchResult
    .map(item => ({Value: item?.type, Name: item?.type}))

  useEffect(() => {
    if (!searchResult?.length) return
    setThemeOptions(getExistingCategories({category: 'themes'}))
    setGenreOptions(getExistingCategories({category: 'genre'}))
    setTypeOptions(uniqBy(getExistingType(), 'Name'))
  }, [searchResult])

  const defaultOptions = () => {
    setThemeOptions(options.themes)
    setTypeOptions(options.types)
    setGenreOptions(options.genre)
  }

  useEffect(() => {
    defaultOptions()
  }, [options])

  const handleSearch = (option, value) => {
    setIsSearchStarted(true)
    if (!selectedGenre && !selectedTheme && !selectedType) {
      defaultOptions()
    }
    switch (option) {
      case 'types':
        dispatch(searchArchives({
          searchGenre: selectedGenre !== 'All' ? selectedGenre : '',
          searchTheme: selectedTheme !== 'All' ? selectedTheme : '',
          searchType: value !== 'All' ? value : ''
        }))
        setSelectedType(value)
        break
      case 'themes':
        dispatch(searchArchives({
          searchGenre: selectedGenre !== 'All' ? selectedGenre : '',
          searchTheme: value !== 'All' ? value : '',
          searchType: selectedType !== 'All' ? selectedType : ''
        }))
        setSelectedTheme(value)
        break
      case 'genre':
        dispatch(searchArchives({
          searchGenre: value !== 'All' ? value : '',
          searchTheme: selectedTheme !== 'All' ? selectedTheme : '',
          searchType: selectedType !== 'All' ? selectedType : ''
        }))
        setSelectedGenre(value)
        break
    }
  }

  return (
    <div className="my-20 text-gray-400" style={{minHeight: 'calc(100vh - 320px)'}}>
      <div className="text-center mb-5 text-yellow-600 font-bold text-2xl">Browse Archive</div>
      <div className="grid grid-cols-1 md:grid-cols-3 text-sm gap-5 m-2">
        <div className="grid grid-cols-1">
          <label className="text-blue-400 text-lg">Select Type</label>
          <Dropdown>
            <Dropdown.Toggle
              className="w-full text-gray-400 text-left border rounded-md outline-none border-gray-700 text-lg rounded-sm p-3 block bg-zinc-700"
              variant="dark"
            >
              {isSearchStarted ? selectedType || 'Select' : !selectedType ? 'Select' : 'All'}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item
                className="hover:no-underline focus:no-underline active:no-underline"
                onClick={() => handleSearch('types', 'All')}>All</Dropdown.Item>
              <Dropdown.Divider/>
              {typeOptions?.length ? typeOptions?.map(
                item => (
                  <Dropdown.Item
                    className="hover:no-underline focus:no-underline active:no-underline"
                    onClick={() => handleSearch('types', item.Value)}
                    key={item.Value}
                  >
                    {item.Name}
                  </Dropdown.Item>
                )
              ) : ('')}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <label className="text-blue-400 text-lg">Select Theme</label>
          <Dropdown>
            <Dropdown.Toggle
              className="w-full text-gray-400 text-left border rounded-md outline-none border-gray-700 text-lg rounded-sm p-3 block bg-zinc-700"
              variant="dark"
            >
              {isSearchStarted ? selectedTheme || 'Select' : !selectedTheme ? 'Select' : 'All'}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark" align="start" style={{maxHeight: '50vh', overflowY: "auto", width: '100%'}}>
              <Dropdown.Item
                className="hover:no-underline focus:no-underline active:no-underline"
                onClick={() => handleSearch('themes', 'All')}>All</Dropdown.Item>
              <Dropdown.Divider/>
              {themeOptions?.length ? themeOptions?.map(
                item => (
                  <Dropdown.Item
                    className="hover:no-underline focus:no-underline active:no-underline"
                    onClick={() => handleSearch('themes', item.Value)}
                    key={item.Value}
                  >
                    {item.Name}
                  </Dropdown.Item>
                )
              ) : ('')}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <label className="text-blue-400 text-lg">Select Genre</label>
          <Dropdown>
            <Dropdown.Toggle
              className="w-full text-gray-400 text-left border rounded-md outline-none border-gray-700 text-lg rounded-sm p-3 block bg-zinc-700"
              variant="dark"
            >
              {isSearchStarted ? selectedGenre || 'Select' : !selectedGenre ? 'Select' : 'All'}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark" style={{maxHeight: '50vh', overflowY: "auto", width: '100%'}}>
              <Dropdown.Item
                className="hover:no-underline focus:no-underline active:no-underline"
                onClick={() => handleSearch('genre', 'All')}>All</Dropdown.Item>
              <Dropdown.Divider/>
              {genreOptions?.length ? genreOptions?.map(
                item => (
                  <Dropdown.Item
                    className="hover:no-underline focus:no-underline active:no-underline"
                    onClick={() => handleSearch('genre', item.Value)}
                    key={item.Value}
                  >
                    {item.Name}
                  </Dropdown.Item>
                )
              ) : ('')}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {
        status.loading ? (
            <Spinner/>
          ) :
          searchResult?.length ? (
              <div className="mt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                {
                  searchResult?.map(
                    film => <FilmCard key={film.id} film={film}/>
                  )
                }
              </div>
            ) :
            (isSearchStarted && !status.loading ? (
              <div className="text-gray-400 text-center text-uppercase flex justify-center items-center"
                   style={{minHeight: '50vh'}}>
                No films found with that criteria.
              </div>
            ) : '')
      }

    </div>
  )
}

export default Archives