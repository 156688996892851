import apiService from './api.service';

const filmService = {
  getFilmList: () => apiService.get('/films'),
  getFilmDetail: ({ id }) => apiService.get(`/film?film_id=${id}`),
  visitFilm: ({ id }) => apiService.get(`/film/${id}/visit`),
  getRelatedFilms: ({ id }) => apiService.get(`/film/${id}/related-videos`),
  getFeaturedFilms: () => apiService.get('/films/featured-videos'),
  getNewFilms: () => apiService.get('/films/new-videos'),
  getPopularFilms: () => apiService.get('/films/popular-videos'),
};

export default filmService;
