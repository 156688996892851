import React from 'react'
import {useSelector} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel as ReactCarousel} from 'react-responsive-carousel';
import {API_BASE_URL} from "../../shared/constants/app.constant";

const Carousel = () => {
  const {banners} = useSelector(state => state.banners)
  return (
    <ReactCarousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      className="rounded m-2"
      swipeable
      swipeScrollTolerance={10}
      useKeyboardArrows
      emulateTouch
    >{banners?.length ? banners?.map(banner => (
      <div key={banner.id}>
        <img src={`${API_BASE_URL}${banner.url}`} alt="" className="rounded-md"/>
      </div>
    )) : ''}
    </ReactCarousel>
  )
}

export default Carousel