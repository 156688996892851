import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CloseOutlined, SearchOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router";
import optionSlice from "../shared/reducers/option.slice";
import searchSlice from "../shared/reducers/search.slice";
import {Dropdown} from "react-bootstrap";
import {isMobile, isTablet} from "react-device-detect";

function SearchBar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {showSearchBar} = useSelector((state) => state.search);
  const {searchMetaOptions} = useSelector((state) => state.options);
  const [searchText, setSearchText] = useState('')
  const [searchField, setSearchField] = useState('')
  const {getFilmMetas} = optionSlice.actions
  const {toggleSearchBar} = searchSlice.actions

  const hideSearch = () => {
    dispatch(toggleSearchBar(false))
    removeListener()
    setSearchText('')
    setSearchField('')
  }

  const onSearch = (event) => {
    event.preventDefault()

    if (!searchText) {
      return true
    }

    navigate(`/search/${searchField?.value || 'all'}/${encodeURIComponent(searchText)}`)
    hideSearch()
  }

  const handleClickOutside = (event) => {
    if (
      event.target.id !== 'search-icon' &&
      showSearchBar &&
      SearchRef?.current &&
      !SearchRef.current?.contains(event.target)
    ) {
      hideSearch()
    }
  }

  const removeListener = () => {
    if (isMobile || isTablet) {
      document.removeEventListener('touchstart', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }
  }
  useEffect(() => {
    if (isMobile || isTablet) {
      document.addEventListener('touchstart', handleClickOutside)
    } else {
      document.addEventListener('click', handleClickOutside)
    }
  }, [showSearchBar])

  useEffect(() => {
    if (showSearchBar) {
      SearchInput.current.focus()
    }
  }, [showSearchBar])

  useEffect(() => {
    dispatch(getFilmMetas())

    return () => {
      setSearchText('')
      setSearchField('')
    }
  }, [])

  const SearchRef = useRef()

  const SearchInput = useRef()

  return (
    <form
      className={`transition-all ease-in ${!showSearchBar ? 'h-0 overflow-hidden' : 'block h-[150px] sm:h-[130px] md:h-[70px]'}`}
      onSubmit={onSearch}
      ref={SearchRef}>
      <div
        className="bg-zinc-800 gap-5 space-y-2 justify-content-center justify-center items-center md:space-y-0 md:flex content-center z-10 sm:px-2 p-5 md:px-20 sm:py-2"
      >
        <Dropdown>
          <Dropdown.Toggle
            className="w-full text-gray-400 text-left border rounded-md outline-none border-gray-700 text-lg rounded-sm p-3 block bg-zinc-700"
            variant="dark"
          >
            {searchField?.name ? searchField?.name : 'All'}
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark" style={{maxHeight: '50vh', overflowY: "auto", width: '300px'}}>
            {searchMetaOptions?.length ? searchMetaOptions?.map(
              item => (
                <Dropdown.Item
                  className="hover:no-underline focus:no-underline active:no-underline"
                  onClick={() => setSearchField(item)}
                  key={item.name}
                >
                  {item.name}
                </Dropdown.Item>
              )
            ) : ('')}
          </Dropdown.Menu>
        </Dropdown>

        <div className="flex flex-1 bg-zinc-700 border-gray-500 rounded">
          <input type="search"
                 ref={SearchInput}
                 className="bg-zinc-700 rounded-md p-3 text-gray-400 focus:ring-0 w-full text-lg border-gray-500 dark:placeholder-gray-400 border-0 ring-0 outline-0"
                 value={searchText}
                 onChange={(e) => setSearchText(e.target.value)}
          />
          <button type="submit" className="text-gray-500 text-md pr-3" style={{marginTop: -5}}>
            <SearchOutlined/>
          </button>
        </div>
      </div>
    </form>
  )
}

export default SearchBar;
