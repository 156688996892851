import React from 'react';

function Footer() {
  return (
    <div className="text-center bg-[#333333] text-sm text-gray-400 py-3 w-full relative bottom-0">
      &copy; Mindanao Film &amp; Television Development Foundation, Inc.
    </div>
  );
}

export default Footer;
