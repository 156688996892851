import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import FilmFullscreen from "../modals/film-fullscreen";

const CustomControls = (
  {
    progress,
    handlePlayPause,
    handlePlayPauseFullscreen,
    playing,
    duration,
    onMouseDown,
    onChange,
    onMouseUp,
    showFullscreen,
    isFullscreen
  }) => {
  return (
    <div className="flex w-full items-center gap-3 p-3">
      <button type="button" className="btn bg-red-500 btn-danger" onClick={() => {
        if (isFullscreen) {
          handlePlayPauseFullscreen()
        } else {
          handlePlayPause()
        }
      }}>
        {!playing ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play"
               viewBox="0 0 16 16">
            <path
              d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"/>
          </svg>) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause"
               viewBox="0 0 16 16">
            <path
              d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
          </svg>
        )}
      </button>

      <div className="flex-1">
        <input
          className="w-full"
          type='range' min={0} max={0.999999} step='any'
          value={progress?.played || 0}
          onMouseDown={onMouseDown}
          onChange={onChange}
          onMouseUp={onMouseUp}
        />
      </div>
      {duration ? (
        <div className="text-gray-400 text-sm">
          {parseInt(progress?.playedSeconds / 59, 10).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:{parseFloat(progress?.playedSeconds % 59).toFixed(0)} &nbsp;/&nbsp;
          {parseInt(duration / 59, 10).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
          })}:{parseFloat(duration % 60).toFixed(0)}
        </div>
      ) : ''}
      {isFullscreen ? (
          <button className="text-sm text-gray-400" onClick={showFullscreen}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <defs/>
              <g id="Stockholm-icons-/-General-/-Scale" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon id="Bound" points="0 0 24 0 24 24 0 24"/>
                <path d="M10,14 L5,14 C4.33333333,13.8856181 4,13.5522847 4,13 C4,12.4477153 4.33333333,12.1143819 5,12 L12,12 L12,19 C12,19.6666667 11.6666667,20 11,20 C10.3333333,20 10,19.6666667 10,19 L10,14 Z M15,9 L20,9 C20.6666667,9.11438192 21,9.44771525 21,10 C21,10.5522847 20.6666667,10.8856181 20,11 L13,11 L13,4 C13,3.33333333 13.3333333,3 14,3 C14.6666667,3 15,3.33333333 15,4 L15,9 Z" id="Combined-Shape" fill="currentColor" fillRule="nonzero"/>
                <path d="M3.87867966,18.7071068 L6.70710678,15.8786797 C7.09763107,15.4881554 7.73079605,15.4881554 8.12132034,15.8786797 C8.51184464,16.2692039 8.51184464,16.9023689 8.12132034,17.2928932 L5.29289322,20.1213203 C4.90236893,20.5118446 4.26920395,20.5118446 3.87867966,20.1213203 C3.48815536,19.7307961 3.48815536,19.0976311 3.87867966,18.7071068 Z M16.8786797,5.70710678 L19.7071068,2.87867966 C20.0976311,2.48815536 20.7307961,2.48815536 21.1213203,2.87867966 C21.5118446,3.26920395 21.5118446,3.90236893 21.1213203,4.29289322 L18.2928932,7.12132034 C17.9023689,7.51184464 17.2692039,7.51184464 16.8786797,7.12132034 C16.4881554,6.73079605 16.4881554,6.09763107 16.8786797,5.70710678 Z" id="Combined-Shape" fill="currentColor" opacity="0.3"/>
              </g>
            </svg>
          </button>
      ) : (
        <div>
          <button className="text-sm text-gray-400" onClick={showFullscreen}>
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
              <g id="Stockholm-icons-/-General-/-Size" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon id="Bound" points="0 0 24 0 24 24 0 24"/>
                <path
                  d="M18,6 L11,6 C10.3333333,5.88561808 10,5.55228475 10,5 C10,4.44771525 10.3333333,4.11438192 11,4 L20,4 L20,13 C20,13.6666667 19.6666667,14 19,14 C18.3333333,14 18,13.6666667 18,13 L18,6 Z M6,18 L13,18 C13.6666667,18.1143819 14,18.4477153 14,19 C14,19.5522847 13.6666667,19.8856181 13,20 L4,20 L4,11 C4,10.3333333 4.33333333,10 5,10 C5.66666667,10 6,10.3333333 6,11 L6,18 Z"
                  id="Combined-Shape" fill="currentColor" fillRule="nonzero"/>
                <rect id="Rectangle-15" fill="currentColor" opacity="0.3"
                      transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) "
                      x="7" y="11" width="10" height="2" rx="1"/>
              </g>
            </svg>
          </button>
        </div>
      )}
    </div>
  )
}
CustomControls.propTypes = {
  playing: PropTypes.any,
  progress: PropTypes.any,
  isFullscreen: PropTypes.bool,
  showFullscreen: PropTypes.any,
  handlePlayPauseFullscreen: PropTypes.func,
  handlePlayPause: PropTypes.func,
  duration: PropTypes.any,
  onMouseDown: PropTypes.any,
  onChange: PropTypes.any,
  onMouseUp: PropTypes.any,
}
export default CustomControls
