import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import ModalsHoc from "./modals-hoc";
import appSlice from "../../shared/reducers/app.slice";

const FilmFullscreen = ({title, content, isShowFullscreen, controls, onClose}) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => onClose();
  const {showTermsModal} = useSelector(state => state.app)
  const {acceptTerms} = appSlice.actions

  useEffect(() => {
    setShow(isShowFullscreen)
    setTimeout(()=>{
      controlsRef.current?.scrollIntoView()
    })
  }, [isShowFullscreen])

  const controlsRef = useRef()
  return (
    <ModalsHoc
      fullscreen={true}
      heading={null}
      backdrop
      show={show}
      handleShow={handleShow}
      handleClose={handleClose}>
      <PerfectScrollbar>
        <div>
          {content}
        </div>
        <div ref={controlsRef} className="px-2">
          {controls}
        </div>
      </PerfectScrollbar>

    </ModalsHoc>
  )
}
FilmFullscreen.defaultProps = {
  isShowFullscreen: false
}
FilmFullscreen.propTypes = {
  title: PropTypes.any.isRequired,
  isShowFullscreen: PropTypes.bool,
  controls: PropTypes.any,
  content: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
}
export default FilmFullscreen