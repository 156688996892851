import {createSlice} from '@reduxjs/toolkit';
import {
  failedActionStatus, initActionStatus,
  resetActionStatus, successActionStatus,
} from '../constants/status.constant';

const initialState = {
  showTermsModal: true,
  status: {
    submitted: false,
    success: false,
    message: '',
    loading: false,
    type: ''
  }
};

const appSlice = createSlice({
  name: 'app',
  reducers: {
    acceptTerms: (state) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'acceptTerms'
        }
      }
    },
    acceptTermsSuccess: (state) => {
      return {
        ...state,
        showTermsModal: false,
        status: {
          ...successActionStatus,
          type: 'acceptTerms'
        }
      }
    },
    acceptTermsFailed: (state) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'acceptTerms'
        }
      }
    },
    reset: (state) => ({
      ...state,
      status: {
        ...resetActionStatus
      }
    })
  },
  initialState,
});

export default appSlice;
