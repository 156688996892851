import React from 'react'
import PropTypes from 'prop-types'
import FilmSlider from '../film-slider'
import {useNavigate} from "react-router";

const Related = ({film, films}) => {
  const navigate = useNavigate()

  const seeAll = () => {
    navigate(`/film/related/${film?.id}`)
  }

  return (
    <div className="my-10">
      <div className="flex justify-center items-center">
        <h1 className="m-2 flex-1 text-3xl text-gray-400">Related</h1>
        <div className="m-2 text-gray-400 cursor-pointer" onClick={seeAll}>See All</div>
      </div>

      <FilmSlider films={films}/>
    </div>
  )
}

Related.propTypes = {
  film: PropTypes.object.isRequired,
  films: PropTypes.array.isRequired
}
export default Related