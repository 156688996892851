import React from 'react';
import emailQR from '../static/images/mindanaofilmarchive_outlook_com.png';
import fbQR from '../static/images/MFA FACEBOOK PAGE QR CODE.png';
import phoneQR from '../static/images/MFTDFI PHONE NUMBER QR CODE.png';
import mailingQR from '../static/images/MFA MAILING ADDRESS QR CODE.png';
import {APP_PLATFORM} from "../shared/constants/app.constant";

function Contact() {
  return (
    <div className="p-5 text-gray-400" style={{minHeight: 'calc(100vh - 150px)'}}>
      <h1 className="text-yellow-600 font-bold text-2xl">Contact Us</h1>
      <div className="text-sm sm:grid sm:grid-cols-2 grid-rows-2 gap-10 justify-center mt-20 block">
        <div className="mb-10">
          <img src={emailQR} className="w-6/12 rounded-lg mb-3"/>
          Email:
          {' '}
          <span className="text-primary">
            {
              APP_PLATFORM === 'web' ? (
                <a href="mailto:mindanaofilmarchive@outlook.com"
                   className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500" target="_blank"
                   rel="noreferrer">
                  mindanaofilmarchive@outlook.com
                </a>
              ) : 'mindanaofilmarchive@outlook.com'}
            </span>
        </div>

        <div className="mb-10">
          <img src={fbQR} className="w-6/12 rounded-lg mb-3"/>

          Facebook:
          {' '}
          <span className="text-primary">
            {
              APP_PLATFORM === 'web' ? (
                <a href="https://www.facebook.com/MindanaoFilmArchive"
                   className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500" target="_blank"
                   rel="noreferrer">
                  www.facebook.com/mindanaofilmarchive
                </a>) : 'www.facebook.com/MindanaoFilmArchive'}
          </span>
        </div>

        <div className="mb-10">
          <img src={phoneQR} className="w-6/12 rounded-lg mb-3"/>
          Phone: {
          APP_PLATFORM === 'web' ? (
            <a href="tel:+63 82 315 4775"
               className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500" target="_blank"
               rel="noreferrer">
              +63 82 315 4775
            </a>
          ) : '+63 82 315 4775'}
        </div>

        <div className="mb-10">
          <div className="mb-3">
            <img src={mailingQR} className="w-6/12 rounded-lg mb-3"/>
          </div>
          Mailing Address:
          <br/>
          <strong>Mindanao Film Archive</strong>
          <br/>
          Mindanao Film &amp; Television Development Foundation, Inc.
          <br/>
          59-B Aala Building, F. Iñigo (Anda) Street, Davao City, Philippines 8000
        </div>
      </div>
    </div>
  );
}

export default Contact;
