import {createSlice} from '@reduxjs/toolkit';
import {
  failedActionStatus, initActionStatus, successActionStatus, resetActionStatus,
} from '../constants/status.constant';

const initialState = {
  showSensitiveFlags: false,
  status: {
    submitted: false,
    success: false,
    message: '',
    loading: false,
    type: '',
  },
};

const filmsSlice = createSlice({
  name: 'films',
  initialState,
  reducers: {
    getFilms: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFilms',
      },
    }),
    getFilmsSuccess: (state, {payload}) => ({
      ...state,
      films: payload,
      status: {
        ...successActionStatus,
        type: 'getFilms',
      },
    }),
    getFilmsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFilms',
      },
    }),
    getFilm: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFilm',
      },
    }),
    getFilmSuccess: (state, {payload}) => ({
      ...state,
      film: payload,
      status: {
        ...successActionStatus,
        type: 'getFilm',
      },
    }),
    getFilmFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFilm',
      },
    }),
    getFeaturedFilms: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFeaturedFilms',
      },
    }),
    getFeaturedFilmsSuccess: (state, {payload}) => ({
      ...state,
      featuredFilms: payload,
      status: {
        ...successActionStatus,
        type: 'getFeaturedFilms',
      },
    }),
    getFeaturedFilmsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFeaturedFilms',
      },
    }),
    getNewFilms: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getNewFilms',
      },
    }),
    getNewFilmsSuccess: (state, {payload}) => ({
      ...state,
      newFilms: payload,
      status: {
        ...successActionStatus,
        type: 'getNewFilms',
      },
    }),
    getNewFilmsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getNewFilms',
      },
    }),
    getPopularFilms: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getPopularFilms',
      },
    }),
    getPopularFilmsSuccess: (state, {payload}) => ({
      ...state,
      popularFilms: payload,
      status: {
        ...successActionStatus,
        type: 'getPopularFilms',
      },
    }),
    getPopularFilmsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getPopularFilms',
      },
    }),
    getRelatedFilms: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getRelatedFilms',
      },
    }),
    getRelatedFilmsSuccess: (state, {payload}) => ({
      ...state,
      relatedFilms: payload,
      status: {
        ...successActionStatus,
        type: 'getRelatedFilms',
      },
    }),
    getRelatedFilmsFailed: (state, {payload}) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getRelatedFilms',
      },
    }),
    reset: (state) => ({
      ...state,
      status: {
        ...resetActionStatus
      }
    })
  },
});

export default filmsSlice;
