import React, {useEffect} from 'react'
import Carousel from "../components/carousel/carousel";
import {useDispatch, useSelector} from "react-redux";
import Featured from "../components/featured";
import Popular from "../components/popular";
import New from "../components/new";
import filmsSlice from "../shared/reducers/film.slice";
import bannersSlice from "../shared/reducers/banner.slice";
import Spinner from "../components/spinner/spinner";

const App = () => {
  const dispatch = useDispatch()

  const {getFeaturedFilms, getNewFilms, getPopularFilms} = filmsSlice.actions
  const {getBanners} = bannersSlice.actions

  useEffect(() => {
    dispatch(getFeaturedFilms())
    dispatch(getNewFilms())
    dispatch(getPopularFilms())
    dispatch(getBanners())
  }, [])

  const {featuredFilms, popularFilms, newFilms} = useSelector(state => state.films);

  return (
    <div className="py-5">
      <div className="my-5 xs:p-10">
        <Carousel/>
      </div>
      <div className="my-5">
        {featuredFilms?.length ?
          <Featured films={featuredFilms}/>
          : <Spinner/>}
      </div>
      <div className="my-5">
        {newFilms?.length ?
          <New films={newFilms}/>
          : <Spinner/>}
      </div>
      <div className="my-5">
        {popularFilms?.length ?
          <Popular films={popularFilms}/>
          : <Spinner/>}
      </div>
    </div>
  )
}

export default App