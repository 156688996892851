import React from 'react'
import PropTypes from 'prop-types'
import {APP_PLATFORM} from "../../shared/constants/app.constant";

const FilmInfo = ({notes, awards, links}) => {
  return (
    <div
      className="bg-black rounded-b-lg bg-opacity-10 p-5 pb-10 details text-gray-400 grid gap-20 grid-cols-1 sm:grid-cols-2 xl:text-2xl grid-flow-row">
      {notes ? (
        <div>
          <div className="text-2xl text-blue-400">Notes</div>
          <div>{notes}</div>
        </div>
      ) : ''}
      {awards?.length ? <div>
        <div className="text-2xl text-blue-400">Awards</div>
        <div>{awards.map(award => award.tag_name).join(`
                `)}</div>
      </div> : ''}
      {
        links?.length ? (
          <div>
            <div className="text-2xl text-blue-400">Links</div>
            <ul>
              {links
                .map(
                  link => APP_PLATFORM === 'web' ?(
                    <li key={link.tag_name}>
                      <a className="text-blue-200 hover:text-blue-600 underline" href={link.tag_name}>
                        {link.tag_name}
                      </a>
                    </li>
                  ) : link.tag_name
                )}
            </ul>
          </div>
        ) : ''
      }
    </div>
  )
}

FilmInfo.defaultProps = {
  notes: '',
  awards: [],
  links: [],
}

FilmInfo.propTypes = {
  notes: PropTypes.string,
  links: PropTypes.array,
  awards: PropTypes.array,
}
export default FilmInfo