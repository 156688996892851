import React from 'react'
import PropTypes from 'prop-types'
import Image from "react-graceful-image";
import {useNavigate} from "react-router";
import ReactPlayer from "react-player";
import {API_BASE_URL} from "../../shared/constants/app.constant";

const FilmCard = ({film}) => {
  const navigate = useNavigate()

  const goToFilm = () => {
    navigate(`/film/${film.id}`)
    document.scrollTo = 0
  }

  return (
    <div className="px-2 font-sans mb-8 cursor-pointer" onClick={goToFilm}
         data-animate-type="motion-safe:animate-fadeIn">
      <div className="rounded-md shadow-xl text-gray-400 bg-film-card pt-3 pb-10">
        <div className="sm:no-flex md:flex">
          <h1 className="p-2 sm:p-2 uppercase text-md sm:text-2xl text-white xl:text-2xl" style={{overflowWrap: "anywhere"}}>{film.title}</h1>
          <div className="flex-1 p-2 text-left md:text-right">
            <div className="text-xs 2xl:text-lg opacity-40">Year</div>
            <div className="md:text-xs 2xl:text-xl text-sm">{film.year}</div>
          </div>
        </div>
        <Image
          src={`${API_BASE_URL}/uploads/${film.thumbnail}`}
          alt={film.title}
          height={200}
          style={{width: '-webkit-fill-available'}}
          className="shadow-lg"
          noLazyLoad={true}
        />
        <div className="flex">
          <div className="flex-1 px-4 py-4 text-left">
            <div className="text-xs 2xl:text-lg opacity-40">Type</div>
            <div
              className="md:text-xs 2xl:text-xl text-sm">{film?.type?.includes('Short Film') ? 'Short Film' : 'Full-length Film'}</div>
          </div>
          <div className="flex-1 px-4 py-4 text-right">
            <div className="text-xs 2xl:text-lg opacity-40">Runtime</div>
            <div className="md:text-xs 2xl:text-xl text-sm">{film.duration}</div>
          </div>
        </div>
        <p className="mt-2 px-4 text-sm md:text-lg  line-clamp-4 whitespace-pre-wrap">
          {film.description}
        </p>
      </div>
    </div>
  )
}
FilmCard.propTypes = {
  film: PropTypes.object.isRequired
}
export default FilmCard