import React from 'react';
import PropTypes from 'prop-types';

function Tabs({activeTab, setActiveTab, visible}) {
  return (
    <div
      className="flex text-sm md:text-lg overflow-auto text-gray-400 shadow-lg bg-black bg-opacity-50 mt-2 rounded-t-lg rounded-br-lg sm:rounded-br-none"
    >
      <div
        className={`flex items-center text-md px-5 px-5 py-5 rounded-tl-lg cursor-pointer ${activeTab === 'details' ? 'bg-gray-800 bg-opacity-80' : ''}`}
        onClick={() => setActiveTab('details')}
      >
        Details
      </div>
      <div
        className={`flex items-center text-md px-5 px-5 py-5 cursor-pointer ${activeTab === 'credits' ? 'bg-gray-800 bg-opacity-80' : ''}`}
        onClick={() => setActiveTab('credits')}
      >
        Credits
      </div>

      {visible.info ? (
        <div
          className={`flex items-center text-md px-5 px-5 py-5  cursor-pointer ${activeTab === 'info' ? 'bg-gray-800 bg-opacity-80' : ''}`}
          onClick={() => setActiveTab('info')}
        >
          Supplemental Information
        </div>
      ) : ''}

      {visible.media ? (
        <div
          className={`flex items-center text-md px-5 px-5 py-5  rounded-br-lg sm:rounded-br-none cursor-pointer ${activeTab === 'media' ? 'bg-gray-800 bg-opacity-80' : ''}`}
          onClick={() => setActiveTab('media')}
        >
          Supplemental Media
        </div>
      ) : ''}

    </div>
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  visible: PropTypes.object.isRequired,
};

export default Tabs;
