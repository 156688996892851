import React from 'react'
import {useNavigate} from "react-router";

const NoPermission = () => {
  const navigate = useNavigate()

  return (
    <div className="relative text-white bg-[#333333] px-2" style={{opacity: 0.8}}>
      To view this film, please <span
      className="text-blue-400 cursor-pointer" onClick={() => navigate('/contact')}>
     request permission
    </span> from the rights holder
    </div>
  )
}

export default NoPermission
