import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import filmsSlice from "../shared/reducers/film.slice";
import PropTypes from 'prop-types'
import FilmCard from "../components/film-card";
import Spinner from "../components/spinner/spinner";
import {useParams} from "react-router";

const Films = ({type, title}) => {
  const dispatch = useDispatch()
  const match = useParams()
  const {getFilm, getFilms, getFeaturedFilms, getNewFilms, getRelatedFilms, getPopularFilms} = filmsSlice.actions
  const {film, featuredFilms, newFilms, relatedFilms, popularFilms, status} = useSelector(state => state.films)
  const [activeTab, setActiveTab] = useState('details')
  const [films, setFilms] = useState([])
  const [heading, setHeading] = useState(title)
  const {searchResult, status: searchStatus} = useSelector(state => state.search)

  useEffect(() => {
    switch (type) {
      case 'featured':
        dispatch(getFeaturedFilms())
        break
      case 'new':
        dispatch(getNewFilms())
        break
      case 'popular':
        dispatch(getPopularFilms())
        break
      case 'related':
        dispatch(getFilm({id: match.id}))
        dispatch(getRelatedFilms({id: match.id}))
        break
      case 'search':
        dispatch(getNewFilms())
        break
      default:
    }
  }, [match])

  useEffect(() => {
    if (status.submitted && !status.loading) {
      if (status.success) {
        switch (type) {
          case 'featured':
            setFilms(featuredFilms)
            break
          case 'new':
            setFilms(newFilms)
            break
          case 'popular':
            setFilms(popularFilms)
            break
          case 'related':
            setFilms(relatedFilms?.data)
            if (film?.length) {
              setHeading(`Related to ${film[0]?.title}`)
            }
            break
          default:
            setFilms([])
        }
      }
    }
  }, [status])

  useEffect(() => {
    setFilms(searchResult)
  }, [searchResult])

  return !status.loading ? (
    <div>
      <h1 className="text-yellow-600 font-bold text-2xl mt-10 mx-2">{heading}</h1>
      {
        films?.length ? (
          <div className="mt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            {
              films?.map(
                film => <FilmCard key={film.id} film={film}/>
              )
            }
          </div>
        ) : (
          <Spinner/>
        )
      }
    </div>
  ) : (
    <Spinner/>
  )
}

Films.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Films
