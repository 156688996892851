import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import bannersService from '../services/banner.service';
import bannersSlice from '../reducers/banner.slice';

const { getBanners, getBannersFailed, getBannersSuccess } = bannersSlice.actions;

function* getBannersListSaga() {
  try {
    const { data } = yield call(bannersService.getBannerList);
    yield put(getBannersSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(bannersService.getBannerList);
      yield put(getBannersSuccess(data));
    } catch (error) {
      yield put(getBannersFailed(error));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(getBanners, getBannersListSaga),
  ]);
}
