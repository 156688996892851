import axios from 'axios';

const headers = {
  // 'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
  // crossdomain: true,
};

const baseURL = process.env.REACT_APP_API_URL;

const createOptions = {
  baseURL,
  headers,
};

export default axios.create(createOptions);
