import apiService from './api.service';

const searchService = {
  searchArchives: (
    {
      searchGenre = '',
      searchTheme = '',
      searchType = ''
    }) => apiService.get(`/films/category?genre=${searchGenre}&theme=${searchTheme}&type=${searchType}`),
  searchFilms: (
    {
      search = '',
      field = ''
    }) => apiService.get(`/search?search=${search}&field=${field}`),
};

export default searchService;
