import React, {useEffect} from 'react'
import Header from "../layout/header";
import SearchBar from "../layout/search-bar";
import {Route, Routes} from "react-router-dom";
import App from "./app";
import Archives from "./archives";
import About from "./about";
import Kiosk from "./kiosk";
import Terms from "./terms";
import Credits from "./credits";
import Contact from "./contact";
import Films from "./films";
import Search from "./search";
import Film from "./film";
import Footer from "../layout/footer";
import TermsModal from "../components/modals/terms";
import {useLocation, useMatch, useParams} from "react-router";

const AppRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    global.scrollTo(0, 0)
  }, [location])

  return (
    <div className="bg-[#333333] min-h-screen font-sans">
      <TermsModal/>
      <div className="sticky top-0 bg-zinc-900 z-10">
        <Header/>
        <SearchBar/>
      </div>
      <section className="xs:px-10 md:px-20 min-h-[95vh]">
        <div className="lg:w-50 mx-auto xl:max-w-full">
          <Routes>
            <Route key="index" path="/" element={<App/>}/>
            <Route key="browse" element={<Archives/>} path="/browse"/>
            <Route key="about" element={<About/>} path="/about"/>
            <Route key="kiosk" element={<Kiosk/>} path="/kiosk-location"/>
            <Route key="terms" element={<Terms/>} path="/terms-and-conditions"/>
            <Route key="partners" element={<Credits/>} path="/partners"/>
            <Route key="contact" element={<Contact/>} path="/contact"/>
            <Route key="featured" element={<Films title="Featured Films" type="featured"/>} path="/film/featured"/>
            <Route key="search" element={<Search/>} path="/search/:field/:search"/>
            <Route key="new" element={<Films title="New Films" type="new"/>} path="/film/new"/>
            <Route key="related" element={<Films title="Related Films" type="related"/>} path="/film/related/:id"/>
            <Route key="popular" element={<Films title="Popular Films" type="popular"/>} path="/film/popular"/>
            <Route key="film" element={<Film/>} path="/film/:id"/>
            <Route key="preview" element={<Film/>} path="/preview/:id"/>
          </Routes>
        </div>
      </section>
      <Footer/>
    </div>
  )
}

export default AppRoutes