import React, {useState} from 'react';
import mapDavao from '../static/images/CinemathequeDavaoMap.png';
import mapNabunturan from '../static/images/map_nabunturan.png';
import mapCDO from '../static/images/City Museum of Cagayan de Oro Map.png';
import {APP_PLATFORM} from "../shared/constants/app.constant";

function Kiosk() {
  const [mapWidth, setMapWidth] = useState({
    davao: 'w-6/12',
    nabunturan: 'w-6/12',
    cdo: 'w-6/12',
  });

  const toggleMap = (location) => {
    switch (location) {
      case 'davao':
        setMapWidth({...mapWidth, davao: mapWidth.davao === 'w-screen' ? 'w-6/12' : 'w-screen'});
        break;
      case 'nabunturan':
        setMapWidth({...mapWidth, nabunturan: mapWidth.nabunturan === 'w-screen' ? 'w-6/12' : 'w-screen'});
        break;
      case 'cdo':
        setMapWidth({...mapWidth, cdo: mapWidth.cdo === 'w-screen' ? 'w-6/12' : 'w-screen'});
        break;
    }
  };

  const CinemathequeDVOFB = 'facebook.com/CinemathequeDVO'
  const cinemathequenabunturanFB = 'facebook.com/CinemathequeNabunturan'

  return (
    <div className="p-5 text-gray-400" style={{minHeight: 'calc(100vh - 150px)'}}>
      <h1 className="text-yellow-600 font-bold text-2xl">Kiosk Locations</h1>
      <div className="mb-20 mt-5">
        <img src={mapDavao} className={`${mapWidth.davao} rounded-lg mb-3 img-responsive`}
             onClick={() => toggleMap('davao')}/>
        <h4 className="text-blue-400 text-2xl">Cinematheque Centre Davao</h4>
        J. Palma Gil Street, Davao
        City
        Philippines 8000

        <p> Hours: Tuesday-Saturday 10am-6pm </p>
        <p> Phone: {
          APP_PLATFORM === 'web' ? (
              <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500"
                 href="tel:+63 82 282 4579"
                 target="_blank" rel="noreferrer">+63 82 282 4579 </a>
            ) :
            '+63 82 282 4579 '
        } </p>
        <p> Email: cinemathequedavao@fdcp.ph </p>
        <p>
          {' '}
          Facebook: &nbsp;
          <span className="text-primary">
            {
              APP_PLATFORM === 'web' ? (
                <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500" href={`https://www.${CinemathequeDVOFB}`} target="_blank" rel="noreferrer">
                  {CinemathequeDVOFB}
                </a>
              ) : CinemathequeDVOFB
            }
          </span>
        </p>
        <p> Coordinates: {
          APP_PLATFORM === 'web' ? (
              <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500"
                 href="https://goo.gl/maps/NkgCrTDLgZ3fcqZd9"
                 target="_blank" rel="noreferrer">
                7.069626, 125.609169
              </a>
            ) :
            '7.069626, 125.609169'
        } </p>
      </div>
      <div className="mb-20 mt-5">
        <img src={mapNabunturan} className={`${mapWidth.nabunturan} rounded-lg mb-3 img-responsive`}
             onClick={() => toggleMap('nabunturan')}/>
        <h4 className="text-blue-400 text-2xl">
          Cinematheque Centre Nabunturan
        </h4>
        Layug Avenue, Purok 13,
        Nabunturan, Davao de Oro
        Philippines 8800
        <p>Hours: Tuesday-Saturday 10am-6pm</p>
        <p>
          {' '}
          Facebook: &nbsp;
          <span className="text-primary">
            {
              APP_PLATFORM === 'web' ? (
                <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500" href={`https://www.${cinemathequenabunturanFB}`} target="_blank"
                   rel="noreferrer">
                  {cinemathequenabunturanFB}
                </a>
              ) : cinemathequenabunturanFB
            }
          </span>
        </p>
        <p> Coordinates: {
          APP_PLATFORM === 'web' ? (
              <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500"
                 href="https://goo.gl/maps/JVb1BJdZydEPLD2j7"
                 target="_blank" rel="noreferrer">
                7.602664, 125.965506
              </a>
            ) :
            '7.602664, 125.965506'
        }</p>

      </div>
      <div className="mb-20 mt-5">
        <img src={mapCDO} className={`${mapWidth.cdo} mb-3 rounded-lg img-responsive`}
             onClick={() => toggleMap('cdo')}/>
        <h4 className="text-blue-400 text-2xl">
          City Museum of Cagayan de Oro
        </h4>
        Dolores Street,
        Cagayan de Oro City,
        Philippines 9000
        <p>Hours: Monday-Friday 9:30am-5pm</p>
        <p> Phone: {
          APP_PLATFORM === 'web' ? (
              <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500"
                 href="tel:+63 88 859 2885"
                 target="_blank" rel="noreferrer">+63 88 859 2885</a>
            ) :
            '+63 88 859 2885'
        } ; {
          APP_PLATFORM === 'web' ? (
              <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500"
                 href="tel:+63 915 881 0719"
                 target="_blank" rel="noreferrer">+63 915 881 0719</a>
            ) :
            '+63 915 881 0719'
        }</p>
        <p> Coordinates: {
          APP_PLATFORM === 'web' ? (
              <a className="underline hover:text-blue-500 focus:text-blue-500 active:text-blue-500"
                 href="https://goo.gl/maps/vJHZMtZq52jS6XPg7"
                 target="_blank" rel="noreferrer">
                8.474808, 124.642253
              </a>
            ) :
            '8.474808, 124.642253'
        }
        </p>
      </div>
    </div>
  );
}

export default Kiosk;
