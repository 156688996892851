import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import appSlice from "../reducers/app.slice";


const { acceptTerms, acceptTermsSuccess, acceptTermsFailed } = appSlice.actions;

function* acceptTermsSaga() {
  try {
    yield put(acceptTermsSuccess());
  } catch (error) {
    yield put(acceptTermsFailed(error));
  }
}

export default function* root() {
  yield all([
    takeLatest(acceptTerms, acceptTermsSaga),
  ]);
}
