import React from 'react';
import PropTypes from 'prop-types';

function FilmDetails({ details }) {
  return (
    <div
      className="bg-black rounded-b-lg bg-opacity-10 p-5 pb-10 details text-gray-400 grid gap-10 grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 xl:text-2xl grid-flow-row"
    >
      {details.map((item) => {
        if (!item.value) return;
        return (
          <div
            key={item.label}
            className="mt-7"
          >
            <div className="text-blue-400">{item.label}</div>
            <div>{item.value}</div>
          </div>
        );
      })}
    </div>
  );
}

FilmDetails.propTypes = {
  details: PropTypes.array.isRequired,
};

export default FilmDetails;
