import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal';

const ModalsHoc = ({children, heading, show, handleShow, handleClose, backdrop, fullscreen}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      keyboard={false}
      backdrop={backdrop}
      fullscreen={fullscreen}
      contentClassName="bg-zinc-800"
      size="xl"
      centered
    >
      <div className="relative w-full h-full md:h-auto">
        <div className="relative bg-zinc-800 rounded-lg">
          <div className="flex justify-between items-start p-4">
            <h1 className="text-2xl font-semibold text-gray-300">
              {heading}
            </h1>
          </div>
          <div className="text-zinc-900">
            {children}
          </div>
        </div>
      </div>
    </Modal>
  )
}

ModalsHoc.defaultProps = {
  show: false,
  children: '',
  backdrop: 'static',
  fullscreen: false,
  heading: ''
}

ModalsHoc.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  handleShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  backdrop: PropTypes.any,
  fullscreen: PropTypes.any,
}


export default ModalsHoc