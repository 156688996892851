import React from 'react';
import MFTDFI from '../static/images/partners-logo/MFTDFI.png';
import NCCA from '../static/images/partners-logo/NCCA.png';
import FDCP from '../static/images/partners-logo/FDCP.png';
import MTCNI from '../static/images/partners-logo/MTCNI.png';
import NABIFILMEX from '../static/images/partners-logo/NABIFILMEX.png';
import developer from '../static/images/partners-logo/eatsleepcode.png';
import Cagayan_de_Oro_official_seal from '../static/images/partners-logo/Cagayan_de_Oro_official_seal,_2014.png';

function Credits() {
  const imgStyle = {height: 175, objectFit: 'scale-down'}
  return (
    <div className="p-5 text-gray-400"  style={{minHeight: 'calc(100vh - 150px)'}}>
      <h1 className="text-yellow-600 font-bold text-2xl">Credits</h1>
      <h4 className="text-xl text-blue-400" style={{marginTop: 50}}>Institutional Partners</h4>
      <div className="hidden sm:grid grid-cols-6 gap-4">
        <div className="col-start-3 col-span-1">
          <img src={MFTDFI} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="span col-span-1">
          <img src={NCCA} className="img-responsive" style={imgStyle}/>
        </div>
      </div>
      <div className="hidden sm:grid grid-cols-5 gap-4 mt-5">
        <div className="col-start-2 col-span-1">
          <img src={FDCP} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="col-span-1">
          <img src={MTCNI} className="img-responsive"  style={imgStyle}/>
        </div>
        <div className="col-span-1">
          <img src={NABIFILMEX} className="img-responsive"  style={imgStyle}/>
        </div>
      </div>
      <div className="hidden sm:grid grid-cols-6 gap-4 mt-5">
        <div className="col-start-3 col-span-1">
          <img
            src={Cagayan_de_Oro_official_seal}
            className="img-responsive"
            style={imgStyle}
          />
        </div>
        <div className="col-span-1">
          <img src={developer} className="img-responsive" style={imgStyle}/>
        </div>
      </div>
      <div className="sm:hidden grid grid-cols-3 justify-content-center">
        <div className="mt-10">
          <img src={MFTDFI} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="mt-10">
          <img src={NCCA} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="mt-10">
          <img src={FDCP} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="mt-10">
          <img src={MTCNI} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="mt-10">
          <img src={NABIFILMEX} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="mt-10">
          <img src={Cagayan_de_Oro_official_seal} className="img-responsive" style={imgStyle}/>
        </div>
        <div className="col-start-2 mt-10">
          <img src={developer} className="img-responsive" style={imgStyle}/>
        </div>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-20 space-y-10 grid-10 sm:space-y-10 md:space-y-0"
      >
        <div>
          <h4 className="text-blue-400 mt-5">Curator</h4>
          <p>Jay Rosas</p>
        </div>
        <div>
          <h4 className="text-blue-400 mt-5">Program Manager</h4>
          <p>Lou Rafael &quot;Dax&quot; Canedo</p>
        </div>
        <div>
          <h4 className="text-blue-400 mt-5">Researchers</h4>
          <p>Ivan Archibald Tadena</p>
          <p>Sakura Yamamoto</p>
        </div>
        <div>
          <h4 className="text-blue-400 mt-5">Logo Design</h4>
          <p>Drei Boquiren</p>
        </div>
        <div>
          <h4 className="text-blue-400 mt-5">Software Developer</h4>
          <p>EatSleepCode.Ninja</p>
        </div>
      </div>
    </div>
  );
}

export default Credits;
