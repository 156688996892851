import { createSlice } from '@reduxjs/toolkit';
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus,
} from '../constants/status.constant';

const initialState = {
  options: {
    types: [],
    genre: [],
    themes: [],
  },
  status: {
    submitted: false,
    success: false,
    message: '',
    loading: false,
    type: '',
  },
};

const optionSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    getFilmTypes: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFilmTypes',
      },
    }),
    getFilmTypesSuccess: (state, { payload }) => ({
      ...state,
      options: {
        ...state.options,
        types: payload,
      },
      status: {
        ...successActionStatus,
        type: 'getFilmTypes',
      },
    }),
    getFilmTypesFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFilmTypes',
      },
    }),
    getFilmThemes: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFilmThemes',
      },
    }),
    getFilmThemesSuccess: (state, { payload }) => ({
      ...state,
      options: {
        ...state.options,
        themes: payload,
      },
      status: {
        ...successActionStatus,
        type: 'getFilmThemes',
      },
    }),
    getFilmThemesFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFilmThemes',
      },
    }),
    getFilmGenre: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFilmGenre',
      },
    }),
    getFilmGenreSuccess: (state, { payload }) => ({
      ...state,
      options: {
        ...state.options,
        genre: payload,
      },
      status: {
        ...successActionStatus,
        type: 'getFilmGenre',
      },
    }),
    getFilmGenreFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFilmGenre',
      },
    }),
    getFilmMetas: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getFilmMetas',
      },
    }),
    getFilmMetasSuccess: (state, { payload }) => ({
      ...state,
      searchMetaOptions: payload,
      status: {
        ...successActionStatus,
        type: 'getFilmMetas',
      },
    }),
    getFilmMetasFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getFilmMetas',
      },
    }),
    reset: (state) => ({
      ...state,
      status: {
        ...resetActionStatus,
      },
    }),
  },
});

export default optionSlice;
