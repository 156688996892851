import React from 'react'
import {slickSettings} from "../../shared/constants/slick-settings.constant";
import FilmCard from "../film-card";
import Slider from "react-slick";
import PropTypes from 'prop-types'

const FilmSlider = ({films}) => (
  <Slider {...slickSettings}>
    {films?.length ? films.map(film => <FilmCard key={film.id} film={film}/>) : ''}
  </Slider>
)

FilmSlider.propTypes = {
  films: PropTypes.array.isRequired
}
export default FilmSlider
