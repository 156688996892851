import { combineReducers } from '@reduxjs/toolkit';
import filmsSlice from './film.slice';
import bannersSlice from './banner.slice';
import optionSlice from './option.slice';
import searchSlice from './search.slice';
import appSlice from "./app.slice";

const rootReducer = combineReducers({
  app: appSlice.reducer,
  films: filmsSlice.reducer,
  banners: bannersSlice.reducer,
  options: optionSlice.reducer,
  search: searchSlice.reducer,
});

export default rootReducer;
