import { createSlice } from '@reduxjs/toolkit';
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus,
} from '../constants/status.constant';

const initialState = {
  banners: [],
  status: {
    submitted: false,
    success: false,
    message: '',
    loading: false,
    type: '',
  },
};

const bannersSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getBanners: (state) => ({
      ...state,
      status: {
        ...initActionStatus,
        type: 'getBanners',
      },
    }),
    getBannersSuccess: (state, { payload }) => ({
      ...state,
      banners: payload,
      status: {
        ...successActionStatus,
        type: 'getBanners',
      },
    }),
    getBannersFailed: (state, { payload }) => ({
      ...state,
      status: {
        ...failedActionStatus,
        message: payload,
        type: 'getBanners',
      },
    }),
    reset: (state) => ({
      ...state,
      status: {
        ...resetActionStatus,
      },
    }),
  },
});

export default bannersSlice;
