import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import FilmSlider from '../film-slider';

function Featured({ films }) {
  const navigate = useNavigate();

  const seeAll = () => {
    try {
      navigate('/film/featured');
    } catch (e) {
      console.log({ error: e });
    }
  };

  return (
    <div className="my-10">
      <div className="flex justify-center items-center">
        <h1 className="m-2 flex-1 text-3xl text-gray-400">Featured</h1>
        <div className="m-2 text-gray-400 cursor-pointer" onClick={seeAll}>See All</div>
      </div>

      <FilmSlider films={films} />
    </div>
  );
}
Featured.propTypes = {
  films: PropTypes.array.isRequired,
};
export default Featured;
