import React from 'react'

const WebTerms = () => {
  return (
    <div className="p-5 text-gray-400">
      <p className="mb-5">
        This service is for personal use only. It is prohibited to display, project, transmit, download, copy, or modify any of its content.
      </p>
      <p className="mb-5">
        If you continue to use the service, you are agreeing to comply with and be bound by its Terms of Use.
      </p>
      <hr />

      <h1 className="text-yellow-600 font-bold text-2xl my-5">Terms of Use</h1>

      <p>
        The term &apos;Mindanao Film & Television Development Foundation,
        Inc.&apos; or &apos;us&apos; or &apos;we&apos; refers to
        the owner of this
        service whose registered office is 59-B, Aala Building, F. Iñigo Street, Davao City, Philippines. The
        term &apos;you&apos;
        refers to the user or viewer of this service.
      </p>

      <p className="my-5">The use of this service is subject to the following terms of use: </p>

      <h4 className="mt-5 text-blue-400 text-xl">Section 1: Copyright</h4>

      <ul className="list-disc px-4">
        <li className="py-2">
          <p>
            This service contains material that is owned by or licensed to us. This material includes, but is not
            limited to moving and still images, audio clips, music, design, layout, and graphics. Reproduction is
            prohibited other than in accordance with the copyright notice, which forms part of these terms of use.
          </p>
        </li>
        <li className="py-2">
          <p>
            It is prohibited to take videos, photographs, and audio recordings, as well as to copy, download, capture,
            transfer, transmit, broadcast, display, reproduce, publish, revise, create derivative works from, license,
            or sell any copyrighted content obtained from this service unless expressly authorized by us.
          </p>
        </li>
        <li className="py-2">
          <p>
            The content of this service is for your personal use only. We do not permit you to use films, footages,
            images or any other material on this service in presentations and exhibitions for the general public,
            community groups, schools, colleges, universities and other educational institutions, conferences,
            exhibitions or any other type of event unless expressly authorized by Mindanao Film & Television Development
            Foundation in writing.
          </p>
        </li>
        <li className="py-2">
          <p>
            If you wish to obtain a copy of any of the content on the Mindanao Film Archive, you must seek written
            permission from the rights holder. We do not have the authority to produce copies of material without the
            express written permission of the rights holder.
          </p>
        </li>
        <li className="py-2">
          <p>
            Any potential income for the use of any content will be referred back to the rights holder.
          </p>
        </li>
      </ul>

      <h4 className="mt-5 text-blue-400 text-xl">Section 2: Content</h4>

      <ul className="list-disc px-4">
        <li className="py-2">
          <p>
            The content of this service is for your general information and use only. It is subject to change without
            notice.
          </p>
        </li>
        <li className="py-2">
          <p>
            Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
            performance, completeness or suitability of the information and materials found or offered on this service
            for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or
            errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent
            permitted by law.
          </p>
        </li>
        <li className="py-2">
          <p>
            Your use of any information or materials on this service is entirely at your own risk, for which we shall
            not be liable. It shall be your own responsibility to ensure that any information available through this
            service meet your specific requirements.
          </p>
        </li>
        <li className="py-2">
          <p>
            Unauthorized use of this service may give rise to a claim for damages and/or be a criminal offense.
          </p>
        </li>
        <li className="py-2">
          <p>
            This service includes links to external websites. These links are provided for your convenience to provide
            further information. They do not signify that we endorse the websites. We have no responsibility for the
            content of the linked websites. Your use of an external site may be governed by the terms and conditions of
            that external site.
          </p>
        </li>
      </ul>

      <h4 className="mt-5 text-blue-400 text-xl">Section 3: Governing Law</h4>

      <ul className="list-disc px-4">
        <li className="py-2">
          <p>
            Your use of this service and any dispute arising out of such use of the service shall be governed,
            construed, and interpreted in accordance with the laws of the Republic of the Philippines, without giving
            effect to principles of conflicts of law.
          </p>
        </li>
        <li className="py-2">
          <p>
            The venue of whatever legal action that may be instituted shall be exclusively in Davao City, Philippines
            only.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default WebTerms
