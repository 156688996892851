import {
  all, put, call, takeLatest,
} from 'redux-saga/effects';

import filmsSlice from '../reducers/film.slice';
import filmService from '../services/film.service';

const {
  getFilm,
  getFilmFailed,
  getFilmSuccess,
  getFeaturedFilms,
  getFeaturedFilmsFailed,
  getFeaturedFilmsSuccess,
  getNewFilms,
  getNewFilmsFailed,
  getNewFilmsSuccess,
  getPopularFilms,
  getPopularFilmsFailed,
  getPopularFilmsSuccess,
  getRelatedFilms,
  getRelatedFilmsFailed,
  getRelatedFilmsSuccess,
} = filmsSlice.actions;

function* getFilmSaga(action) {
  try {
    const {data} = yield call(filmService.getFilmDetail, action.payload);
    yield call(filmService.visitFilm, action.payload);
    yield put(getFilmSuccess(data));
  } catch (error) {
    try {
      const {data} = yield call(filmService.getFilmDetail, action.payload);
      yield put(getFilmSuccess(data));
    } catch (error) {
      yield put(getFilmFailed(error));
    }
  }
}

function* getFeaturedFilmsSaga() {
  try {
    const {data} = yield call(filmService.getFeaturedFilms);
    yield put(getFeaturedFilmsSuccess(data));
  } catch (error) {
    try {
      const {data} = yield call(filmService.getFeaturedFilms);
      yield put(getFeaturedFilmsSuccess(data));
    } catch (error) {
      yield put(getFeaturedFilmsFailed(error));
    }
  }
}

function* getNewFilmsSaga() {
  try {
    const {data} = yield call(filmService.getNewFilms);
    yield put(getNewFilmsSuccess(data));
  } catch (error) {
    try {
      const {data} = yield call(filmService.getNewFilms);
      yield put(getNewFilmsSuccess(data));
    } catch (error) {
      yield put(getNewFilmsFailed(error));
    }
  }
}

function* getPopularFilmsSaga() {
  try {
    const {data} = yield call(filmService.getPopularFilms);
    yield put(getPopularFilmsSuccess(data));
  } catch (error) {
    try {
      const {data} = yield call(filmService.getPopularFilms);
      yield put(getPopularFilmsSuccess(data));
    } catch (error) {
      yield put(getPopularFilmsFailed(error));
    }
  }
}

function* getRelatedFilmSaga(action) {
  try {
    const {data} = yield call(filmService.getRelatedFilms, action.payload);
    yield put(getRelatedFilmsSuccess(data));
  } catch (error) {
    try {
      const {data} = yield call(filmService.getRelatedFilms, action.payload);
      yield put(getRelatedFilmsSuccess(data));
    } catch (error) {
      yield put(getRelatedFilmsFailed(error));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(getFilm, getFilmSaga),
    takeLatest(getRelatedFilms, getRelatedFilmSaga),
    takeLatest(getFeaturedFilms, getFeaturedFilmsSaga),
    takeLatest(getNewFilms, getNewFilmsSaga),
    takeLatest(getPopularFilms, getPopularFilmsSaga),
  ]);
}
