import React from 'react'
import {useNavigate} from "react-router";
import PropTypes from 'prop-types'

const KioskOnly = ({isForKiosk}) => {
  const navigate = useNavigate()
  return (
    <div className="relative text-white bg-[#333333] px-2" style={{opacity: 0.7}}>
      This film can only be viewed on our <span className="text-blue-400 cursor-pointer" onClick={() => {
      if (isForKiosk) {
        navigate('/kiosk-location')
      } else {
        navigate('/contact')
      }
    }}>interactive kiosks</span>
    </div>
  )
}
KioskOnly.propTypes = {
  isForKiosk: PropTypes.bool.isRequired
}
export default KioskOnly