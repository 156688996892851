import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import optionSlice from '../reducers/option.slice';
import optionService from '../services/option.service';

const {
  getFilmGenre,
  getFilmGenreFailed,
  getFilmGenreSuccess,
  getFilmThemes,
  getFilmThemesFailed,
  getFilmThemesSuccess,
  getFilmTypes,
  getFilmTypesFailed,
  getFilmTypesSuccess,
  getFilmMetas,
  getFilmMetasSuccess,
  getFilmMetasFailed
} = optionSlice.actions;

function* getFilmGenreSaga() {
  try {
    const { data } = yield call(optionService.getGenre);
    yield put(getFilmGenreSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(optionService.getGenre);
      yield put(getFilmGenreSuccess(data));
    } catch (error) {
      yield put(getFilmGenreFailed(error));
    }
  }
}

function* getFilmThemesSaga() {
  try {
    const { data } = yield call(optionService.getThemes);
    yield put(getFilmThemesSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(optionService.getThemes);
      yield put(getFilmThemesSuccess(data));
    } catch (error) {
      yield put(getFilmThemesFailed(error));
    }
  }
}

function* getFilmMetasSaga() {
  try {
    const { data } = yield call(optionService.getFilmMetas);
    yield put(getFilmMetasSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(optionService.getFilmMetas);
      yield put(getFilmMetasSuccess(data));
    } catch (error) {
      yield put(getFilmMetasFailed(error));
    }
  }
}

function* getFilmTypesSaga() {
  try {
    const { data } = yield call(optionService.getTypes);
    yield put(getFilmTypesSuccess(data));
  } catch (error) {
    try {
      const { data } = yield call(optionService.getTypes);
      yield put(getFilmTypesSuccess(data));
    } catch (error) {
      yield put(getFilmTypesFailed(error));
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(getFilmGenre, getFilmGenreSaga),
    takeLatest(getFilmThemes, getFilmThemesSaga),
    takeLatest(getFilmTypes, getFilmTypesSaga),
    takeLatest(getFilmMetas, getFilmMetasSaga),
  ]);
}
