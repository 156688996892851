import apiService from './api.service';

const optionService = {
  getGenre: () => apiService.get('/options/option-values/genre'),
  getTypes: () => apiService.get('/options/option-values/types'),
  getThemes: () => apiService.get('/options/option-values/themes'),
  getFilmMetas: () => apiService.get('/options/film-values'),
};

export default optionService;
